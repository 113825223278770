.pairings-round {
  background-color: var(--color-background-bg-pairings);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.pairings-round .container-wrapper {
  height: 844px;
  width: 390px;
}

.pairings-round .container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 804px;
  left: 20px;
  position: relative;
  top: 40px;
  width: 350px;
}

.pairings-round .title {
  align-self: stretch;
  color: transparent;
  font-family: var(--headings-font-family);
  font-size: var(--headings-font-size);
  font-style: var(--headings-font-style);
  font-weight: var(--headings-font-weight);
  letter-spacing: var(--headings-letter-spacing);
  line-height: var(--headings-line-height);
  margin-top: -1px;
  position: relative;
}

.pairings-round .span {
  color: var(--color-navigation-light);
  font-family: var(--headings-font-family);
  font-size: var(--headings-font-size);
  font-style: var(--headings-font-style);
  font-weight: var(--headings-font-weight);
  letter-spacing: var(--headings-letter-spacing);
  line-height: var(--headings-line-height);
}

.pairings-round .text-wrapper-2 {
  color: var(--color-states-pairings);
  font-family: var(--headings-font-family);
  font-size: var(--headings-font-size);
  font-style: var(--headings-font-style);
  font-weight: var(--headings-font-weight);
  letter-spacing: var(--headings-letter-spacing);
  line-height: var(--headings-line-height);
}

.pairings-round .subnav {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 350px;
}

.pairings-round .message {
  color: var(--color-navigation-nav);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.pairings-round .pairings {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.pairings-round .pairings-entry-instance {
  flex: 0 0 auto !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  margin-top: -1px !important;
}

.pairings-round .design-component-instance-node {
  flex: 0 0 auto !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.pairings-round .pairings-entry-2 {
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.pairings-round .title-2 {
  align-self: stretch;
  color: var(--color-navigation-next);
  font-family: var(--headings-font-family);
  font-size: var(--headings-font-size);
  font-style: var(--headings-font-style);
  font-weight: var(--headings-font-weight);
  letter-spacing: var(--headings-letter-spacing);
  line-height: var(--headings-line-height);
  position: relative;
  text-align: right;
}
