:root {
  --hp360-font-family: aktiv-grotesk, Helvetica;
  --hp360-font-weight: 800;
  --hp360-font-size: 60px;
  --hp360-letter-spacing: -2px;
  --hp360-line-height: 46px;
  --hp360-font-style: normal;
  --pre-header-font-family: aktiv-grotesk, Helvetica;
  --pre-header-font-weight: 800;
  --pre-header-font-size: 30px;
  --pre-header-letter-spacing: -0.6px;
  --pre-header-line-height: 28px;
  --pre-header-font-style: normal;
  --header-font-family: aktiv-grotesk, Helvetica;
  --header-font-weight: 800;
  --header-font-size: 60px;
  --header-letter-spacing: -1.2px;
  --header-line-height: 51px;
  --header-font-style: normal;
  --sub-nav-font-family: aktiv-grotesk, Helvetica;
  --sub-nav-font-weight: 700;
  --sub-nav-font-size: 18px;
  --sub-nav-letter-spacing: 0px;
  --sub-nav-line-height: 22px;
  --sub-nav-font-style: normal;
  --entry-number-font-family: aktiv-grotesk, Helvetica;
  --entry-number-font-weight: 500;
  --entry-number-font-size: 18px;
  --entry-number-letter-spacing: 0px;
  --entry-number-line-height: 22px;
  --entry-number-font-style: normal;
  --name-font-family: aktiv-grotesk, Helvetica;
  --name-font-weight: 400;
  --name-font-size: 18px;
  --name-letter-spacing: 0px;
  --name-line-height: 22px;
  --name-font-style: normal;
  --standing-font-family: aktiv-grotesk, Helvetica;
  --standing-font-weight: 400;
  --standing-font-size: 14px;
  --standing-letter-spacing: 0px;
  --standing-line-height: 20px;
  --standing-font-style: normal;
  --resistance-font-family: aktiv-grotesk, Helvetica;
  --resistance-font-weight: 300;
  --resistance-font-size: 12px;
  --resistance-letter-spacing: 0px;
  --resistance-line-height: 22px;
  --resistance-font-style: normal;
  --name-won-font-family: aktiv-grotesk, Helvetica;
  --name-won-font-weight: 500;
  --name-won-font-size: 18px;
  --name-won-letter-spacing: 0px;
  --name-won-line-height: 22px;
  --name-won-font-style: normal;
  --primitives-color-dark: rgba(0, 0, 0, 1);
  --primitives-color-dropshadow: rgba(0, 0, 0, 0.3);
  --primitives-color-light: rgba(255, 255, 255, 1);
  --primitives-color-navigation-nav: rgba(0, 114, 188, 1);
  --primitives-color-navigation-greyed-out: rgba(102, 102, 102, 1);
  --primitives-color-navigation-next: rgba(0, 178, 5, 1);
  --primitives-color-navigation-alert: rgba(255, 69, 0, 1);
  --primitives-color-states-hp360: rgba(234, 80, 125, 1);
  --primitives-color-states-players: rgba(0, 114, 188, 1);
  --primitives-color-states-pairings: rgba(165, 156, 0, 1);
  --primitives-color-states-standings: rgba(255, 192, 0, 1);
  --primitives-color-states-inprogress: rgba(195, 33, 111, 1);
  --primitives-color-states-edit: rgba(255, 69, 0, 1);
  --primitives-color-background-BG-hp360: rgba(32, 11, 17, 1);
  --primitives-color-background-BG-add-players: rgba(0, 25, 41, 1);
  --primitives-color-background-BG-pairings: rgba(255, 254, 246, 1);
  --primitives-color-background-BG-inprogress: rgba(35, 7, 20, 1);
  --primitives-color-background-BG-standings: rgba(48, 36, 0, 1);
  --primitives-color-background-BG-final-standings: rgba(34, 26, 0, 1);
  --primitives-color-background-BG-edit: rgba(46, 12, 0, 1);
  --primitives-color-background-BG-dialog: rgba(255, 254, 246, 0.6);
  --primitives-color-background-BG-final-standings: linear-gradient(180deg, #735600 0%, #3d2e00 100%);
}
